<script>
export default {
  data(){
    return{
      tableData: null,
      total: 0
    }
  },
  created() {
    this.getTableList()
  },
  methods:{
    /**
     * 获取车辆列表
     */
    getTableList(){
      this.axios.get(this.$api.publicUrl.VehicleList, {
        params: {
          pageSize: 0,
          pageNum: 1
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.total = res.data.data.total
          this.initData(res.data.data.list)
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "车辆品牌", type: "text", key: "carBrand", prop: "carBrand"},
            {label: "车系", type: "text", key: "carSeries", prop: "carSeries"}],
          [{label: "车牌号", type: "text", key: "plateNo", prop: "plateNo"},
            {label: "车架号", type: "text", key: "vinNumber", prop: "vinNumber"}],
          [{label: "发动机号", type: "text", key: "engineNumber", prop: "engineNumber"},
            {label: "变速箱类型", type: "select", key: "gearType", prop: "gearType", options: [
                {value: "1", label: "燃油"},
                {value: "2", label: "电动"},
                {value: "3", label: "油电混动"},
              ]}],
          [{label: "排量(升)", type: "text", key: "outputVolume", prop: "outputVolume"},
            {label: "座位数", type: "number", key: "sitNumber", prop: "sitNumber"}],
          [{label: "年份", type: "text", key: "produceYear", prop: "produceYear"},
            {label: "当前里程(km)", type: "number", key: "currentMileage", prop: "currentMileage", class: "text_line"}],
          [{label: "下次保养里程(km)", type: "number", key: "maintainMileage", prop: "maintainMileage", class: "text_line"},
            {label: "车辆颜色", type: "text", key: "carColor", prop: "carColor"}],
          [{label: "车辆状态", type: "select", key: "useStatus", prop: "useStatus", options: [
              {value: 1, label: "空闲"},
              {value: 2, label: "占用"}
            ]}
          ],
          [
            {label: "保险名称", type: "text", key: "insuranceName", prop: "insuranceName"},
            {label: "保险有效期", type: "date", key: "insuranceExpirationTime", prop: "insuranceExpirationTime"
              , valueFormat: 'yyyy-MM-dd HH:mm:ss'}
          ]
        ],
        formData: data || {
          carBrand: "",
          carSeries: "",
          plateNo: "",
          vinNumber: "",
          engineNumber: "",
          gearType: "",
          energyType: "",
          outputVolume: "",
          sitNumber: "",
          produceYear: "",
          currentMileage: "",
          maintainMileage: "",
          carColor: "",
          useStatus: 1,
          insuranceName: "",
          insuranceExpirationTime: ""
        },
        rules: {
          carBrand: [{ required: true, message: '请输入车辆品牌', trigger: 'blur' }],
          carSeries: [{ required: true, message: '请输入车系', trigger: 'blur' }],
          plateNo: [{ required: true, message: '请输入车牌号', trigger: 'blur'}],
          gearType: [{ required: true, message: '请选择变速箱类型', trigger: 'change'}],
          energyType: [{ required: true, message: '请选择能源类型', trigger: 'change'}],
          sitNumber: [{ required: true, message: '请输入座位数', trigger: 'blur'}],
          currentMileage: [{ required: true, message: '请输入当前里程', trigger: 'blur'}],
          maintainMileage: [{ required: true, message: '请输入下次保养里程', trigger: 'blur'}],
          useStatus: [{ required: true, message: '请选择状态', trigger: 'change'}],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 新增
     */
    addUserInfo(){
      this.createTemplateData("新增车辆", "add")
    },
    /**
     * 修改
     * @param data 行数据
     */
    updateCarInfo(data){
      this.createTemplateData("修改车辆","update", data)
    },
    /**
     * 删除
     * @param id
     */
    delCarInfo(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendService(id, "del")
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /**
     * 弹出层回调事件
     * 这里可能需要针对某些接口做数据预处理， 最后将数据发送给服务器
     * @param data
     * @param mode
     */
    poPupCallback(data, mode){
      this.sendService(data, mode)
    },
    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "add":
            this.axios.post(this.$api.publicUrl.VehicleList, data).then(res =>{
              if(res.data.status === 200){
                resolve("添加成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "update":
            this.axios.put(this.$api.publicUrl.VehicleList, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "del":
            this.axios.delete(this.$api.publicUrl.DelVehicle + "/" + data ).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.getTableList()
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
        this.$message.error(err);
      })
    },
    /**
     * 初始化数据
     * @param data
     */
    initData(data){
      let arr = []
      for (let x = 0; x < data.length; x++){
        for(let y= 0; y < data[x].seriesList.length; y++){
          let num = true
          for(let z = 0; z <  data[x].seriesList[y].carInfoList.length; z++){
            let obj = {
              brand: null,
              series: null,
              ...data[x].seriesList[y].carInfoList[z]
            }
            if(num){
              if(y === 0){
                obj.brand = data[x].brand
                obj.brandNum = 0
                data[x].seriesList.forEach(item => obj.brandNum += item.carInfoList.length)
              }
              num = false
            }
            if(z === 0){
              obj.series = data[x].seriesList[y].series
              obj.seriesNum = data[x].seriesList[y].carInfoList.length
            }
            arr.push(obj)
          }
        }
      }
      if(!arr.length){
        return
      }
      this.tableData = this.$lodash.cloneDeep(arr)
    }
  }
}

</script>

<template>
  <fragment v-if="tableData">
    <section class="control">
      <div class="con_ti">车辆总数<span>{{this.total}}</span></div>
      <div class="btn">
        <el-button type="primary" @click="addUserInfo" size="small">新增</el-button>
      </div>
    </section>
    <section class="tabulation">
      <table>
        <tr>
          <th rowspan="2">品牌</th>
          <th rowspan="2">车系</th>
          <th colspan="2">状态</th>
        </tr>
        <tr>
          <th>空闲</th>
          <th>占用</th>
        </tr>
        <tr v-for="(row, index) in this.tableData" :key="index">
          <td v-if="row.brand" :rowspan="row.brandNum" class="_brand">{{row.brand}}</td>
          <td v-if="row.series" :rowspan="row.seriesNum" class="_series">{{row.series}}</td>
          <td class="_status">
            {{row.useStatus === 1? row.plateNo : ""}}
            <span v-if="row.useStatus === 1 && row.needMaintain" class="on">需要保养</span>
            <span v-if="row.useStatus === 1 && row.insuranceExpiration && row.insuranceExpirationTime" class="on span_two">保险到期</span>
          </td>
          <td class="_status">
            {{row.useStatus === 2? row.plateNo : ""}}
            <span v-if="row.useStatus === 2 && row.needMaintain" class="on">需要保养</span>
            <span v-if="row.useStatus === 2 && row.insuranceExpiration && row.insuranceExpirationTime" class="on span_two">保险到期</span>
          </td>
          <td>
            <div class="t_btn">
              <el-button @click="updateCarInfo(row)" size="small" type="success">修改</el-button>
              <el-button @click="delCarInfo(row.id)" size="small" type="danger">删除</el-button>
            </div>
          </td>
        </tr>
      </table>
    </section>
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>
<style scoped>
table{
  width: 100%;
  border-collapse: collapse
}
.span_two{

}
th, td{
  padding: 10px 20px;
  border: 1px solid #ddd;
}
td{
  min-height: 42px;
}
._status{
  position: relative;
}
._status span{

}
tr{
  position: relative;
  transition: all 0.3s;
}
tr:hover{
  background-color: #dedede;
}
tr:hover .t_btn{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
._brand, ._series{
  background-color: white!important;
  text-align: center;
}
tr:nth-child(1), tr:nth-child(2){
  pointer-events: none;
}
.on{
  background-color: #358f6c;
  color: white;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 20px;
}
tr td:last-child{
  position: sticky;
  width: 0;
  right: 0;
  padding: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
}
.t_btn{
  position: absolute;
  right: 0;
  display: flex;
  opacity: 0;
  padding: 0 20px;
  transition: all 0.3s;
  pointer-events: none;
  background-color: #dedede;

}
</style>
